import api from "../service/ApiService";
import { baseUrl } from "../service/ApiUrls";
import { RemoteCastSessionStatus } from "../Enums";
import { confirmAlert } from "react-confirm-alert";
import { cssNumber } from "jquery";

navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mediaDevices.getUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia;

var loggedIn = false;
var configuration = {
  iceServers: [
    // {
    //   urls: "turn:180.210.129.103:3478?transport=udp",
    //   username: "citlrtc",
    //   credential: "c1tlr7c",
    // },
    {
      urls: "stun:180.210.129.103:3478",
      username: "citlrtc",
      credential: "c1tlr7c",
    },
    {
      urls: "turn:180.210.129.103:3478?transport=udp",
      username: "citlrtc",
      credential: "c1tlr7c",
    },
  ],
};
var pc, sender;
var peer;
var localstream = null;
var hasGotAnswer = true;
var flag = true;
var candidates = [];
var rvideo = document.getElementById("remoteView");
var sock;

function logError(error) {
  console.log(error.name + ": " + error.message);
}

function callUpdateApi(dataID, status, dataToSend, imei) {
  dataToSend["id"] = dataID;
  dataToSend["status"] = status;
  console.log("dataToSend", dataToSend);
  api
    .put(
      baseUrl + "/remote-cast/" + imei.imei + "/session/" + dataID,
      dataToSend
    )
    .then((res) => {
      console.log("posting data with result", res.data);
    })
    .catch((err) => {
      console.log("error in posting data", err);
    });
}
var dataID = null;
var imei;
var androidId;
let startButton = document.getElementById("startButton");
var x = document.createElement("INPUT");
x.setAttribute("type", "hidden");
x.setAttribute("id", "dataID");

function buttonChangeOfToStart() {
  //getCheckboxesValue();
  console.log("button passed", document.getElementById("startButton"));
  let startButton = document.getElementById("startButton");
  imei = JSON.parse(document.getElementById("imei").value);
  // androidId = JSON.parse(document.getElementById("androidId").value);

  console.log("imei", imei.apiData, imei.imei);
  // document.getElementById("startButton").disabled = false;
  // document.getElementById('startButton').setAttribute("disabled","disabled");
  startButton.removeAttribute("disabled");
  startButton.classList.remove("disabled");
  startButton.classList.add("enabled");

  startButton.value = "Start";
  startButton.innerHTML = "Start";

  let dataToSend = {
    imei: imei.androidId,
    // androidId: androidId.androidId,
    settings: settingsObj,
    status: RemoteCastSessionStatus.INITIATED,
  };

  console.log("post data to send ", dataToSend);

  startButton.addEventListener("click", function (e) {
    console.log("e is ", e.target.value);
    document.getElementById("recording").classList.add("p-highlight");
    document.getElementsByClassName("p-button-label")[0].innerHTML = "Record";
    if (e.target.value === "Start") {
      console.log("imei", imei);
      //document.getElementById();

      if (document.getElementById("masterDiv") !== null) {
        document.getElementById("masterDiv").appendChild(x);
      }

      let enableVoiceCall = document.getElementById("enableVoiceCall");
      let enableFileShare = document.getElementById("enableFileShare");
      let allowToStopCast = document.getElementById("allowToStopCast");
      settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
      settingsObj["enableFileShare"] = enableFileShare.checked;
      settingsObj["allowToStopCast"] = allowToStopCast.checked;

      dataToSend["settings"] = settingsObj;
      dataToSend["status"] = RemoteCastSessionStatus.INITIATED;

      api
        .post(baseUrl + "/remote-cast/" + imei.imei + "/session", dataToSend)
        .then((res) => {
          dataID = res.data.data;
          x.setAttribute("value", dataID);
          console.log("posting data with result", res.data, dataID, x);
        })
        .catch((err) => {
          console.log("error in posting data", err);
        });
      //setTimeout(function () {

      offer(imei.androidId, settingsObj);
      // offer("bd41fe4ec9a9bc08", gSettings);
      //}, 5000);
      startButton.value = "Stop";
      startButton.innerHTML = "Stop";
      console.log("e is ", startButton.value);
      document.getElementById("segmentToShow").style.visibility = "visible";
      remoteVideoResize();
      //value = "Stop";
    } else if (e.target.value === "Stop") {
      console.log("imei", imei);

      let enableVoiceCall = document.getElementById("enableVoiceCall");
      let enableFileShare = document.getElementById("enableFileShare");
      let allowToStopCast = document.getElementById("allowToStopCast");
      settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
      settingsObj["enableFileShare"] = enableFileShare.checked;
      settingsObj["allowToStopCast"] = allowToStopCast.checked;

      dataToSend["settings"] = settingsObj;
      callUpdateApi(
        dataID,
        RemoteCastSessionStatus.COMPLETED,
        dataToSend,
        imei
      );
      //setTimeout(fu
      stop();
      startButton.value = "Start";
      startButton.innerHTML = "Start";
      document.getElementById("segmentToShow").style.visibility = "hidden";
      if (document.getElementById("remoteView").srcObject) {
        document.getElementById("remoteView").srcObject = null;
        document.getElementById("remoteView").src = null;
        console.log("Source object null");
      } else {
        document.getElementById("remoteView").src = null;
        console.log("source object remote view set to null");
      }
    }
  });
  //startButton.click();
  console.log("button changed", document.getElementById("startButton"));
}

function remoteVideoResize() {
  console.log("Function Call");
  rvideo !== null &&
    rvideo.addEventListener("loadedmetadata", function () {
      console.log(
        "Remote video videoWidth: " +
          this.videoWidth +
          "px,  videoHeight: " +
          this.videoHeight +
          "px"
      );
    });

  if (rvideo !== null) {
    rvideo.onresize = function () {
      console.log(
        "Remote video size changed to " +
          rvideo.videoWidth +
          "x" +
          rvideo.videoHeight
      );
    };
  }
}
var gusername;
export function connect(username) {
  gusername = username;
  console.log("connect");
  var loc = window.location;
  // var uri = "ws://180.210.129.214:8080/signal";
  // var uri = "wss://www.mobionizer.com:8443/wrtc/signal";
  // var uri = "wss://ibbl.mobionizer.com:8443/wrtc/signal";
  // var uri = "wss://mdm.commlinkinfotech.com:8443/wrtc/signal";
  var uri = "wss://mdmdev.commlinkinfotech.com:8443/wrtc/signal";
  // var uri = "ws://192.16.0.128:8080/wrtc/signal";
  sock = new WebSocket(uri);

  sock.onopen = function (e) {
    console.log("open", e);
    sock.send(
      JSON.stringify({
        type: 0,
        data: {
          user: username,
          media: 0,
        },
      })
    );
    // should check better here, it could have failed
    // moreover not logout implemented
    loggedIn = true;
  };

  sock.onclose = function (e) {
    console.log("close", e);
    stop();
  };

  sock.onerror = function (e) {
    console.log("error", e);
    confirmAlert({
      title: "Message",
      message: "Failed to connect. Do you want to retry ?? ",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            connect(gusername);
          },
        },
        {
          label: "No",
          onClick: () => {}, //window.location = },
        },
      ],
    });
  };

  sock.onmessage = function (e) {
    console.log("message", e.data);

    var message = JSON.parse(e.data);
    if (message.type === 4) {
      if (message.data.status === 0) {
        console.log("socket connected");
        // get a local stream, show it in a self-view and add it to be sent
        if (
          navigator.userAgent.toLowerCase().includes("firefox") ||
          navigator.userAgent.toLowerCase().includes("safari")
        ) {
          navigator.mediaDevices
            .getUserMedia({
              audio: { echoCancellation: true },
            })
            .then(function (stream) {
              // document.getElementById("selfView").srcObject = localStream;
              localstream = stream;
              refreshmedia();
              if (!pc) {
                startRTC();
              }
              buttonChangeOfToStart();

              //localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));
            })
            .catch((err) => {
              console.log("error in the navigator", err);
            });
        } else {
          navigator.getUserMedia(
            {
              audio: { echoCancellation: true },
            },
            function (stream) {
              try {
                localstream = stream;
                refreshmedia();
                if (!pc) {
                  startRTC();
                }
                buttonChangeOfToStart();
              } catch (error) {
                console.log("error", error);
                //selfView.src = window.URL.createObjectURL(stream);
              }
            },
            logError
          );
        }
      }
    }
    if (message.type === 2) {
      enablemedia();
      if (!pc) {
        startRTC();
      }
      if (message.data.sdp) {
        //  		alert('sdp');
        if (message.data.sdp.type == "OFFER") {
          message.data.sdp.type = "offer";
        }
        if (message.data.sdp.type == "ANSWER") {
          message.data.sdp.type = "answer";
        }
        if (message.data.sdp.description) {
          message.data.sdp.sdp = message.data.sdp.description;
          message.data.sdp.description = null;
        }

        pc.setRemoteDescription(
          new RTCSessionDescription(message.data.sdp),
          function () {
            // if we received an offer, we need to answer
            if (pc.remoteDescription.type == "offer") {
              console.log("answer");
              peer = message.dest;
              pc.createAnswer(localDescCreated, logError);
            } else {
              hasGotAnswer = true;
              var ind = 0;
              for (ind = 0; ind < candidates.length; ind++) {
                console.log("sending stored candidate");
                sendMessage(candidates[ind]);
              }
            }
          },
          logError
        );
      } else {
        //		alert('candidate');
        if (message.data.candidate.sdp) {
          message.data.candidate.candidate = message.data.candidate.sdp;
          message.data.candidate.sdp = null;
        }
        pc.addIceCandidate(new RTCIceCandidate(message.data.candidate));
      }
    }
    if (message.type === 1) {
      //disconnect the socket, stop cast session, show message.data.msg in an alert dialog. After user clicks "ok", back to device-list page to remote-connect
      disconnect();
      if (message.data.msg !== null && message.data.msg.length > 0) {
        confirmAlert({
          title: "Message",
          message: message.data.msg,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                //alert("Go to Device list");
                window.location = `${process.env.PUBLIC_URL}/remote-cast`;
              },
            },
          ],
        });
      }
    }
    if (message.type === 3) {
      //stop peer connect i.e. remote cast session.  if message.data.msg contains any message, show message.data.msg in an alert dialog
      let enableVoiceCall = document.getElementById("enableVoiceCall");
      let enableFileShare = document.getElementById("enableFileShare");
      let allowToStopCast = document.getElementById("allowToStopCast");
      settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
      settingsObj["enableFileShare"] = enableFileShare.checked;
      settingsObj["allowToStopCast"] = allowToStopCast.checked;

      let dataToSend = {};
      dataToSend["settings"] = settingsObj;
      callUpdateApi(
        dataID,
        RemoteCastSessionStatus.COMPLETED,
        dataToSend,
        imei
      );
      stop();
      let startButton = document.getElementById("startButton");
      startButton.value = "Start";
      startButton.innerHTML = "Start";
      document.getElementById("segmentToShow").style.visibility = "hidden";
      if (document.getElementById("remoteView").srcObject) {
        document.getElementById("remoteView").srcObject = null;
        document.getElementById("remoteView").src = null;
        console.log("Source object null");
      } else {
        document.getElementById("remoteView").src = null;
        console.log("source object remote view set to null");
      }
      dataID = null;
    }
  };

  //setConnected(true);
}

export function startRTC() {
  pc = new RTCPeerConnection(configuration);
  flag = true;

  // send any ice candidates to the other peer
  pc.onicecandidate = function (evt) {
    if (evt.candidate) {
      console.log("candidate", evt.candidate);
      var jsonOb = {
        sdp: evt.candidate.candidate,
        sdpMLineIndex: evt.candidate.sdpMLineIndex,
        sdpMid: evt.candidate.sdpMid,
        serverUrl: evt.candidate.serverUrl,
      };

      var ic = {
        type: 2,
        dest: peer,
        data: {
          candidate: jsonOb,
        },
      };
      if (hasGotAnswer === true) {
        sendMessage(ic);
      } else {
        candidates.push(ic);
      }
    }
  };

  // once remote stream arrives, sho480w it in the remote video element
  pc.ontrack = function (evt) {
    try {
      document.getElementById("remoteView").srcObject = evt.streams[0];
    } catch (error) {
      document.getElementById("remoteView").src = window.URL.createObjectURL(
        evt.stream
      );
    }
  };

  pc.addEventListener("connectionstatechange", (event) => {
    if (pc.connectionState === "connected") {
      // Peers connected!
      console.log("", "test connected");
      document.getElementById("screenshot").removeAttribute("disabled");
      document.getElementById("screenshot").classList.remove("disabled");
      document.getElementById("screenshot").classList.add("enabled");
      document
        .getElementById("screenshot")
        .addEventListener("click", function (e) {
          screenShot();
          document.getElementById("hiddendisabled").value = "false";
          document.getElementById("recording").classList.remove("p-disabled");
        });
      document.getElementById("hiddendisabled").value = "false";
      document.getElementById("recording").classList.remove("p-disabled");

      console.log(
        document.getElementById("recording"),
        document.getElementById("hiddendisabled")
      );
      let dataToSend = {
        imei: imei.imei,
        settings: settingsObj,
        status: RemoteCastSessionStatus.INITIATED,
      };
      console.log("imei", imei);
      let enableVoiceCall = document.getElementById("enableVoiceCall");
      let enableFileShare = document.getElementById("enableFileShare");
      let allowToStopCast = document.getElementById("allowToStopCast");
      settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
      settingsObj["enableFileShare"] = enableFileShare.checked;
      settingsObj["allowToStopCast"] = allowToStopCast.checked;

      dataToSend["settings"] = settingsObj;
      callUpdateApi(dataID, RemoteCastSessionStatus.ONGOING, dataToSend, imei);
      console.log("session on going");
    } else if (pc.connectionState === "disconnected") {
      // Peers disconnected!
      console.log("", "disconnected");
      disableScreenAndRecording();
      // document.getElementById("hiddendisabled").value = true;
      // document.getElementById("recording").classList.add("p-disabled");

      refreshmedia();
      let startButton = document.getElementById("startButton");
      let enableVoiceCall = document.getElementById("enableVoiceCall");
      let enableFileShare = document.getElementById("enableFileShare");
      let allowToStopCast = document.getElementById("allowToStopCast");
      settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
      settingsObj["enableFileShare"] = enableFileShare.checked;
      settingsObj["allowToStopCast"] = allowToStopCast.checked;
      let dataToSend = {};
      dataToSend["settings"] = settingsObj;
      callUpdateApi(
        dataID,
        RemoteCastSessionStatus.COMPLETED,
        dataToSend,
        imei
      );
      //setTimeout(fu
      if (flag) {
        stop();
      }
      startButton.value = "Start";
      startButton.innerHTML = "Start";
      document.getElementById("segmentToShow").style.visibility = "hidden";
      if (document.getElementById("remoteView").srcObject) {
        document.getElementById("remoteView").srcObject = null;
        document.getElementById("remoteView").src = null;
        console.log("Source object null");
      } else {
        document.getElementById("remoteView").src = null;
        console.log("source object remote view set to null");
      }
      dataID = null;
    } else if (pc.connectionState === "closed") {
      disableScreenAndRecording();
      // Peers disconnected!
      console.log("", "closed");
    } else if (pc.connectionState === "failed") {
      console.log("failed");
      disableScreenAndRecording();

      let startButton = document.getElementById("startButton");
      let enableVoiceCall = document.getElementById("enableVoiceCall");
      let enableFileShare = document.getElementById("enableFileShare");
      let allowToStopCast = document.getElementById("allowToStopCast");
      settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
      settingsObj["enableFileShare"] = enableFileShare.checked;
      settingsObj["allowToStopCast"] = allowToStopCast.checked;
      let dataToSend = {};
      dataToSend["settings"] = settingsObj;
      callUpdateApi(dataID, RemoteCastSessionStatus.FAILED, dataToSend, imei);
      //setTimeout(fu
      //stop();
      if (flag) {
        stop();
      }
      startButton.value = "Start";
      startButton.innerHTML = "Start";
      document.getElementById("segmentToShow").style.visibility = "hidden";
      if (document.getElementById("remoteView").srcObject) {
        document.getElementById("remoteView").srcObject = null;
        document.getElementById("remoteView").src = null;
        console.log("Source object null");
      } else {
        document.getElementById("remoteView").src = null;
        console.log("source object remote view set to null");
      }
      dataID = null;
    }
  });
  if (localstream != null) {
    var audioTracks = localstream.getAudioTracks()[0];
    if (settingsObj.enableVoiceCall) {
      sender = pc.addTrack(audioTracks, localstream);
    }
  }
}

var settingsObj;
export function offer(dest, settings) {
  settingsObj = settings;
  hasGotAnswer = false;
  enablemedia();
  let enableVoiceCall = document.getElementById("enableVoiceCall");
  settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
  //if (!pc) {
  startRTC();
  //}

  peer = dest;

  pc.createOffer(localDescCreated, logError, {
    offerToReceiveAudio: settingsObj.enableVoiceCall,
    offerToReceiveVideo: true,
  });
}

export function makeSettings({
  enableVoiceCall = false,
  enableFileShare = false,
  allowToStopCast = false,
} = {}) {
  settingsObj = {
    enableVoiceCall: enableVoiceCall,
    enableFileShare: enableFileShare,
    allowToStopCast: allowToStopCast,
  };
  return settingsObj;
}

function localDescCreated(desc) {
  console.log("offer");

  pc.setLocalDescription(
    desc,
    function () {
      // obj {type: offer, dest: 2, data: desc}
      console.log(pc.localDescription);

      //   var settingsObj = {
      //     enableVoiceCall: true,
      //     enableFileShare: true,
      //     allowToStopCast: true,
      //   };
      var jsonObj = {
        type: pc.localDescription.type,
        description: pc.localDescription.sdp.replace(
          "a=extmap-allow-mixed\r\n",
          ""
        ),
      };

      sendMessage({
        type: 2,
        dest: peer,
        data: {
          settings: settingsObj,
          sdp: jsonObj,
        },
      });
    },
    logError
  );
}

function sendMessage(payload) {
  var str = JSON.stringify(payload);
  str = str.replace("offer", "OFFER");
  str = str.replace("answer", "ANSWER");
  sock.send(str);
}

function disableScreenAndRecording() {
  if (document.getElementById("screenshot") !== null) {
    document.getElementById("screenshot").removeAttribute("enabled");
    document.getElementById("screenshot").classList.remove("enabled");
    document.getElementById("screenshot").classList.add("disabled");
    document.getElementById("hiddendisabled").value = "true";
    document.getElementById("recording").classList.add("p-disabled");
  }
}

export function disconnect() {
  console.log("disconnect");

  if (dataID !== null) {
    //api
    stop();
    let enableVoiceCall = document.getElementById("enableVoiceCall");
    let enableFileShare = document.getElementById("enableFileShare");
    let allowToStopCast = document.getElementById("allowToStopCast");
    settingsObj["enableVoiceCall"] = enableVoiceCall.checked;
    settingsObj["enableFileShare"] = enableFileShare.checked;
    settingsObj["allowToStopCast"] = allowToStopCast.checked;
    let dataToSend = {};

    dataToSend["settings"] = settingsObj;
    callUpdateApi(dataID, RemoteCastSessionStatus.COMPLETED, dataToSend, imei);
  }
  if (sock != null) {
    sock.close();
  }
  if (localstream) {
    localstream.getTracks().forEach(function (track) {
      // stopping every track
      track.stop();
    });
  }

  // setConnected(false);
}

export function stop() {
  disableScreenAndRecording();

  if (document.getElementById("dataID") !== null) {
    document.getElementById("dataID").setAttribute("value", null);
  }
  if (pc) {
    pc.close();
  }
  pc = null;
  flag = false;
  refreshmedia();
}

function refreshmedia() {
  if (localstream) {
    var audioTracks = localstream.getAudioTracks();
    if (audioTracks && audioTracks.length > 0) {
      audioTracks[0].enabled = false;
    }
    var videoTracks = localstream.getVideoTracks();
    if (videoTracks && videoTracks.length > 0) {
      videoTracks[0].enabled = false;
    }
  }
}

function enablemedia() {
  if (localstream) {
    var audioTracks = localstream.getAudioTracks();
    if (audioTracks && audioTracks.length > 0) {
      audioTracks[0].enabled = true;
    }
    var videoTracks = localstream.getVideoTracks();
    if (videoTracks && videoTracks.length > 0) {
      videoTracks[0].enabled = true;
    }
  }
}

function clearCanvas(canvas) {
  // const canvas = document.getElementById("canvas" + idx);
  canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
}

export function isCanvasBlank(canvas) {
  const context = canvas.getContext("2d");
  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  );
  return !pixelBuffer.some((color) => color !== 0);
}

function copyCanvasData(sourceCanvas, destinationCanvas) {
  //grab the context from your destination canvas
  var destCtx = destinationCanvas.getContext("2d");
  //call its drawImage() function passing it the source canvas directly
  destinationCanvas.width = sourceCanvas.width;
  destinationCanvas.height = sourceCanvas.height;
  destCtx.drawImage(sourceCanvas, 0, 0);
  clearCanvas(sourceCanvas);
}

function putDatainCanvas(v, canvas, ctx) {
  let w, h;
  w = v.videoWidth;
  h = v.videoHeight;
  canvas.width = w;
  canvas.height = h;
  ctx.fillRect(0, 0, w, h);
  ctx.drawImage(v, 0, 0, w, h);
  v.style.backgroundImage = `url(${canvas.toDataURL()})`; // here is the magic
  v.style.backgroundSize = "cover";
  v.style.background = "gray";
}

export function show(elements) {
  elements = elements.length ? elements : [elements];
  for (var index = 0; index < elements.length; index++) {
    elements[index].style.visibility = "visible";
  }
}

export function hide(elements) {
  elements = elements.length ? elements : [elements];
  for (var index = 0; index < elements.length; index++) {
    elements[index].style.visibility = "hidden";
  }
}

function screenShot() {
  let object = document.getElementById("remoteView");
  // let canvas = document.getElementById("canvas"); // declare a canvas element in your html
  // let ctx = canvas.getContext("2d");
  let videos = object;

  let latestCanvas = document.getElementById("canvas0");

  const v = videos;
  if (!v.src) {
  } // no video here
  try {
    for (let canvasidx = 3; canvasidx > 0; canvasidx--) {
      let canvas = document.getElementById("canvas" + canvasidx);
      let prevCanvas = document.getElementById("canvas" + (canvasidx - 1));
      if (isCanvasBlank(canvas)) {
        hide(document.querySelectorAll("#down" + canvasidx));
        hide(document.getElementById("cross" + canvasidx));
        copyCanvasData(prevCanvas, canvas);
      } else {
        show(document.querySelectorAll("#down" + canvasidx));
        show(document.getElementById("cross" + canvasidx));
        copyCanvasData(prevCanvas, canvas);
      }
    }
    putDatainCanvas(v, latestCanvas, latestCanvas.getContext("2d"));
    show(document.querySelectorAll("#down" + "0"));
    show(document.getElementById("cross" + "0"));
    for (let i = 0; i < 4; i++) {
      let canvasCheck = document.getElementById("canvas" + i);
      if (!isCanvasBlank(canvasCheck)) {
        show(document.querySelectorAll("#down" + i));
        show(document.getElementById("cross" + i));
      }
    }

    // ctx.clearRect(0, 0, w, h); // clean the canvas
  } catch (e) {
    console.log(e);
  }

  // html2canvas(canvas).then(function (canvas) {
  //   //document.body.appendChild(canvas);
  //   let base64URL = canvas
  //     .toDataURL("image/jpeg")
  //     .replace("image/jpeg", "image/png");
  //   downloadBase64File("image/png", base64URL, "scr-image-" + new Date());
  //   //console.log(base64URL);
  // });
}
