import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { AllData } from "../service/AllData";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import api from "../service/ApiService";
import { Dialog } from "primereact/dialog";
import ErrorDialog from "../components/ErrorDialog";
import SuccessDialog from "../components/SuccessDialog";
import Loader from "../components/Loader";
import {
  getGroupListUrl,
  getGroupListDevice,
  getDeviceSearchData,
  getCreationDateWiseDevoices,
  getGeofenceAlertsReport,
  baseUrl,
} from "../service/ApiUrls";
import { OverlayPanel } from "primereact/overlaypanel";
import DatePicker from "react-datepicker";
import { FaSearch } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Tooltip } from "primereact/tooltip";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
const helpIcon = require("../assets/icons/help_icon.png");
const FileDownload = require("js-file-download");

export class EnrollmentHistory extends Component {
  constructor(props) {
    super(props);
    let currentDate = new Date();
    this.state = {
      group: null,
      startDate: new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: currentDate,
      errorMessage: "Something went wrong!",
      isLoading: false,
      isError: false,
      visible: false,
      currentPage: 1,
      drows: 20,
      dfirst: 0,
    };
    this.alldata = new AllData();
  }
  onDialogueHide = () => {
    this.setState({ isError: false });
  };

  dateConvert = (date) => {
    // Days you want to subtract

    // var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var fulldate =
      String("00" + day).slice(-2) +
      "-" +
      String("00" + month).slice(-2) +
      "-" +
      year;
    console.log(fulldate);
    return fulldate;
  };

  startChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  stockBodyTemplate(rowData) {
    return (
      <>{rowData.enrollmentDate === null ? "N/A" : rowData.enrollmentDate}</>
    );
  }
  endChange = (date) => {
    this.setState(
      {
        endDate: date,
      },
      () => {
        console.log("Dates", this.state);
        if (this.state.endDate !== null && this.state.startDate !== null) {
          let et = this.convertDate(this.state.endDate).split("/").join("-");
          let st = this.convertDate(this.state.startDate).split("/").join("-");
          console.log(et, st);
          //this.props.callgetEvents({ start: st, end: et });
        }
      }
    );
  };

  convertDate = (date) => {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    var today = new Date(date);
    console.log(today.toLocaleDateString("en-US"));
    return today.toLocaleDateString("en-GB");
  };

  callEnrollmentConfigList = ({
    first = 0,
    limit = 10,
    start = this.dateConvert(this.state.startDate),
    end = this.dateConvert(this.state.endDate),
    profileid,
    groupid,
  } = {}) => {
    this.setState({ isLoading: true }, () => {
      api
        .get(baseUrl + "/enrollment-config-list/enrolled-device-list", {
          params: {
            first: first,
            limit: limit,
            start: start,
            end: end,
            profileid: profileid,
            groupid: groupid,
          },
        })
        .then((res) => {
          if (res.data.result.isError === false) {
            this.setState({ ...res.data.data, isLoading: false });
          } else {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p"> {res.data.result.errorMsg} </p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.callEnrollmentConfigList();
                  },
                },
              ],
            });
          }
        });
    });
  };

  callCrationDateWiseDevices = ({
    geoAlertOrCreationDevice,
    start = this.dateConvert(this.state.startDate),
    end = this.dateConvert(this.state.endDate),
    profileid,
    groupid,
  }) => {
    api
      .get(
        geoAlertOrCreationDevice === true
          ? getCreationDateWiseDevoices
          : getGeofenceAlertsReport,
        {
          responseType: "blob",
          params: {
            start: start,
            end: end,
            profileid: profileid,
            groupid: groupid,
          },
        }
      )
      .then((res) => {
        const fileName = res.headers["content-disposition"].split(
          "filename="
        )[1];
        FileDownload(res.data, fileName);
      })
      .catch((err) => {});
  };

  callDeviceSearchData = () => {
    api
      .get(getDeviceSearchData)
      .then((res) => {
        if (res.data.result.isError === false) {
          console.log("Inside false");
          this.setState(
            {
              profileList: res.data.data.profileList,
              groupList: res.data.data.groupList,
            },
            () => {
              // this.callGroupList();
              // this.setState(
              //   { selectedProfile: this.state.profileList[0] },
              //   () => {
              //     this.callGroupList();
              //   }
              // );
              this.callEnrollmentConfigList();
            }
          );
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        localStorage.setItem("loggedIn", false);
        //window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callGroupList = ({ first = 0, limit = 10, filter, profileId } = {}) => {
    api
      .get(getGroupListUrl, { params: { first, limit, filter, profileId } })
      .then((res) => {
        if (res.data.result.isError === false) {
          this.setState({
            ...res.data.data,
            group: res.data.data.content,
            // rows: res.data.data.totalRecords,
          });
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  callDeleteGroupApi = ({ id, rowData }) => {
    api
      .delete(getGroupListUrl + "/" + id)
      .then((res) => {
        if (res.data.result.isError === false) {
          var array = [...this.state.group]; // make a separate copy of the array
          var index = array.indexOf(rowData);
          if (index !== -1) {
            array.splice(index, 1);
            this.setState(
              { group: array, isSuccess: true },
              () => {}
              //console.log("new state", this.state.cars)
            );
          }
        } else if (res.data.result.isError) {
          this.setState({
            isError: true,
            errorMessage: res.data.result.errorMsg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.callDeviceSearchData();

    //this.alldata.getGroupList().then((data) => this.setState({ group: data }));
  }
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };

  onPage = (event) => {
    //console.log("Event", event);
    //this.callGroupList({ first: event.page, limit: event.rows });

    setTimeout(() => {
      const { first, rows, page } = event;

      this.setState(
        {
          dfirst: first,
          drows: rows,
          page,
          currentPage: page + 1,
        },
        () => {
          this.callEnrollmentConfigList({
            first: event.page,
            limit: event.rows,
            start: this.dateConvert(this.state.startDate),
            end: this.dateConvert(this.state.endDate),
          });
        }
      );
    }, 500);
  };
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Enroll", url: "enroll" },
      { label: "Enrollment History" },
    ];
    const profileList = this.state.profileList;
    let profileOptions =
      profileList !== undefined &&
      profileList.length > 0 &&
      profileList.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const groupList = this.state.groupList;
    let groupOptions =
      groupList !== undefined &&
      groupList.length > 0 &&
      groupList.map((e, i) => {
        //console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });
    const header = (
      <div id="cus-p" className="row justify-content-end">
        <div className="p-col-12 p-md-2 p-lg-2">
          <h2 style={{ textAlign: "left", padding: "28px 0px" }}>
            Enroll Devices
          </h2>
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <div className="form-group" style={{ textAlign: "left" }}>
            <label>Profile</label>
            <select
              className="form-control"
              onChange={(e) => {
                console.log("e in profile ", e.target);
                let profileObject = profileList.find((obj) => {
                  //console.log("object", obj.id);
                  return obj.id === e.target.value;
                });
                //console.log("profile object", profileObject);
                this.setState(
                  {
                    ...this.state,
                    selectedProfile: profileObject,
                  }
                  // () => {
                  //   this.callGroupList({
                  //     profileId:
                  //       this.state.selectedProfile !== null &&
                  //       this.state.selectedProfile !== undefined
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // }
                );
              }}
              id="selectedProfile"
              name="selectedProfile"
              value={
                this.state.selectedProfile !== null &&
                this.state.selectedProfile !== undefined
                  ? this.state.selectedProfile.id
                  : ""
              }
            >
              <option value={null}>Select Profile</option>
              {profileOptions}
            </select>
          </div>
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <div className="form-group" style={{ textAlign: "left" }}>
            <label>Group</label>
            <select
              className="form-control"
              onChange={(e) => {
                console.log("e in profile ", e.target);
                let groupObject = groupList.find((obj) => {
                  //console.log("object", obj.id);
                  return obj.id === e.target.value;
                });
                this.setState(
                  {
                    ...this.state,
                    selectedgroup: groupObject,
                  }
                  // () => {
                  //   this.callGroupList({
                  //     profileId:
                  //       this.state.selectedProfile !== null &&
                  //       this.state.selectedProfile !== undefined
                  //         ? this.state.selectedProfile.id
                  //         : null,
                  //   });
                  // }
                );
              }}
              id="selectedgroup"
              name="selectedgroup"
              value={
                this.state.selectedgroup !== null &&
                this.state.selectedgroup !== undefined
                  ? this.state.selectedgroup.id
                  : ""
              }
            >
              <option value={null}>Select Group</option>
              {groupOptions}
            </select>
          </div>
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <div className="form-group" style={{ textAlign: "left" }}>
            <label>Start Date</label>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.startChange}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              isClearable
              className="date-class p-col-12 p-md-12 p-xl-12"
              // selectsStart={this.state.startDate}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select Start Date"
            />
          </div>
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <div className="form-group" style={{ textAlign: "left" }}>
            <label>End Date</label>
            <DatePicker
              selected={this.state.endDate}
              onChange={this.endChange}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              minDate={this.state.startDate}
              isClearable
              className="date-class p-col-12 p-md-12 p-xl-12"
              // selectsEnd={this.state.endDate}
              dateFormat="dd-MM-yyyy"
              placeholderText="Select End Date"
            />
          </div>
        </div>
        <div className="p-col-12 p-md-2 p-lg-2">
          <div className="c-p">
            <button
              className="btn btn-primary5"
              style={{ float: "left", marginRight: "5px" }}
              onClick={() => {
                this.callEnrollmentConfigList({
                  start: this.dateConvert(this.state.startDate),
                  end: this.dateConvert(this.state.endDate),
                  profileid:
                    this.state.selectedProfile !== null &&
                    this.state.selectedProfile !== undefined
                      ? this.state.selectedProfile.id
                      : null,
                  groupid:
                    this.state.selectedgroup !== undefined
                      ? this.state.selectedgroup.id
                      : null,
                });
              }}
            >
              <FaSearch />
            </button>
            <Tooltip
              target=".export"
              position="top"
              mouseTrack
              mouseTrackTop={20}
            />
            <button
              className="btn btn-primary5 export"
              style={{ float: "left" }}
              data-pr-tooltip="Export"
              disabled={this.state.totalRecords === 0}
              onClick={() => {
                this.callCrationDateWiseDevices({
                  geoAlertOrCreationDevice: true,
                  start: this.dateConvert(this.state.startDate),
                  end: this.dateConvert(this.state.endDate),
                  profileid:
                    this.state.profileid !== undefined
                      ? this.state.profileid
                      : null,
                  groupid:
                    this.state.groupid !== undefined
                      ? this.state.groupid
                      : null,
                });
              }}
            >
              <FaCloudDownloadAlt />
            </button>
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Device Group"
        visible={this.state.visible}
        style={{ width: "35vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p style={{ textAlign: "center" }}>
          <p
            style={{
              fontWeight: "bold",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            All the group members will be shifted to profile.
          </p>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              onClick={(e) => {
                this.setState({ prechecked: e.target.checked });
              }}
            />
            <label className="form-check-label" for="exampleCheck1">
              Preserve the group's apps and manage those device-wise
            </label>
          </div>
        </p>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "dashboard" };
    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 p-ll">
          <div className="p-col-9 inline-b p-ll">
            <BreadCrumb model={items} home={home} />
          </div>
          {/* <div className="p-col-3 inline-b p-zz">
            <img
              src={helpIcon}
              width="20"
              height="20"
              onClick={(e) => this.op.toggle(e)}
              style={{
                marginBottom: "-10px",
                float: "right",
                cursor: "pointer",
              }}
            />
          </div> */}
          <OverlayPanel
            ref={(el) => (this.op = el)}
            id="overlay_panel"
            showCloseIcon={true}
          >
            <div
              className="col"
              style={{
                width: "fit-content",
                maxWidth: "300px",
                padding: "0px",
              }}
            >
              <div className="helptip-title">
                <b>How to : Enrollment History</b>
              </div>
              <div className="helptip-des">
                Users have option to manage devices by group rather than
                individual. One device belongs to only one group and each group
                must have a device profile. Once devices are bound to a group,
                the device profile of that group are automatically applied on
                those devices.
              </div>
            </div>
          </OverlayPanel>
        </div>
        {deleteDialogue}
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHideSuccess}
        >
          <SuccessDialog successMessage="Deleted Group Successfully" />
        </Dialog>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="p-grid bg-col p-lg-12">
              <div className="datatable-paginator-demo">
                <DataTable
                  value={this.state.content}
                  style={{ marginBottom: "20px" }}
                  responsive={true}
                  header={header}
                  emptyMessage="No records found"
                  paginator
                  totalRecords={this.state.totalRecords}
                  onPage={this.onPage}
                  first={this.state.dfirst}
                  currentPage={this.state.currentPage}
                  lazy
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  rows={this.state.drows}
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                >
                  <Column
                    field="imei1"
                    header="IMEI 1"
                    style={{ width: "20%" }}
                  />
                  <Column
                    field="imei2"
                    header="IMEI 2"
                    style={{ width: "15%" }}
                  />
                  <Column
                    field="enrollmentDate"
                    header="Date of Enrollment"
                    body={this.stockBodyTemplate}
                  />
                  <Column
                    field="enrollmentStatusSt"
                    header="Enrollment Status"
                  />
                  <Column field="profileName" header="Profile" />
                  <Column field="groupName" header="Group" />
                  <Column field="statusSt" header="Status" />
                </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.isError}
              modal
              style={{ width: "20vw" }}
              closable
              showHeader
              onHide={this.onDialogueHide}
            >
              <ErrorDialog errorMessage={this.state.errorMessage} />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
