import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../components/Loader";
import SuccessDialog from "../components/SuccessDialog";
import { Dialog } from "primereact/dialog";
import { Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../service/ApiService";
import { postAddEnrollMentUrl, getAddEnrollMentUrl } from "../service/ApiUrls";
import { EnrollmentTypeEnum } from "../Enums";

export class SideMenu extends Component {
  constructor(props) {
    super(props);
    console.log("props", props);

    this.state = {
      visibleRight: false,
      isLoading: false,
      isSuccess: false,
      enrollmentType: [],
      configName: "",
      selectGroupList: [],
      selectProfileList: [],
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  callgetAddEnrollMent = async () => {
    try {
      const res = await api.get(getAddEnrollMentUrl);
      console.log("res", res);
      let {
        data: { content, groupList, profileList },
        result: { isError, errorMsg, statusCode },
      } = res.data;
      console.log(
        "content",
        content,
        "groupList",
        groupList,
        "profileList",
        profileList
      );
      return { content, groupList, profileList, isError, errorMsg, statusCode };
    } catch (err) {
      //localStorage.setItem("loggedIn", false);
      // window.location = process.env.PUBLIC_URL + "/login";
      return err; //console.err(err);
    }
  };
  componentDidMount() {
    //console.log("call of code");
    // let response = this.callgetAddEnrollMent();
    // console.log("result of api", response);
  }

  changeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  submitHandler = async (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    console.log(
      "parameter e in submit ",
      e,
      "selectedGroup ",
      this.state.selectedGroup,
      "selected profile",
      this.state.selectedProfile
    );

    let { id: groupId, osType, osTypeSt } =
      this.state.selectedGroup !== null &&
      this.state.selectedGroup !== undefined &&
      this.state.selectedGroup;
    let { id: profileId } =
      this.state.selectedProfile !== null &&
      this.state.selectedProfile !== undefined &&
      this.state.selectedProfile;
    //let groupId = "id";
    let data = {
      id: this.state.rowId,
      name: this.state.configName,
      groupId,
      osType,
      osTypeSt,
      type: EnrollmentTypeEnum.QR,
      isDefault: this.state.defaultSetting,
      enableAllSystemApp: this.state.enableAllSystemApp,
      profileId,
    };

    if (this.state.isUpdate === false) {
      api.post(postAddEnrollMentUrl, data).then((res) => {
        console.log("res", res);
        let { isError, errorMsg } = res.data.result;
        //
        this.setState({ isError: isError, isLoading: false });
        if (isError === false) {
          this.props.apiParentCall();
          this.setState(
            {
              visibleRight: false,
              isLoading: false,
            },
            () => {
              confirmAlert({
                title: "Success Message",
                message: <p className="mod-sp">Added Successfully</p>,
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        } else if (isError === true) {
          //show error message;
          this.setState({ errorMsg: errorMsg, isLoading: false });
        }
      });
    } else {
      let response = await this.props.apiUpdateCall(data);
      let { isError, errorMsg } = response;
      console.log("Response of Update", response);

      this.setState({
        visibleRight: true,
        errorMsg: errorMsg,
        isError: isError,
        isLoading: false,
      });
      if (isError === false) {
        await this.props.apiParentCall();
        this.setState(
          {
            visibleRight: false,
            // isSuccess: true,
            isLoading: false,
          },
          () => {
            confirmAlert({
              title: "Success Message",
              message: <p className="mod-sp">Updated Successfully</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          }
        );
      }
    }
  };

  launchSide = async (e, toUpdate) => {
    this.setState({
      visibleRight: true,
      errorMsg: "",
      isError: false,
      configName: "",
      isUpdate: false,
      selectedGroup: null,
      selectedProfile: null,
      defaultSetting: false,
      enableAllSystemApp: false,
    });
    console.log("data from parent ", "e", e, "toUpdate", toUpdate);
    const response = await this.callgetAddEnrollMent();
    console.log("Api response of call getAddEnrollMent()", response);
    let { isError, errorMsg, groupList, profileList } = response;
    console.log(
      "Api response destructured",
      isError,
      errorMsg,
      groupList,
      profileList
    );
    this.setState({
      selectGroupList: groupList,
      selectProfileList: profileList,
    });

    if (toUpdate === true) {
      let selectedGroup =
        this.state.selectGroupList.length > 0
          ? this.state.selectGroupList.find((obj) => {
              console.log("obj", obj, "e group", e.groupId);
              return obj.id === e.groupId;
            })
          : null;
      let selectedProfile =
        this.state.selectProfileList.length > 0
          ? this.state.selectProfileList.find((obj) => {
              return obj.id === e.profileId;
            })
          : null;
      console.log(
        "selected group",
        selectedGroup,
        "selected profile",
        selectedProfile
      );
      this.setState({
        isUpdate: true,
        configName: e.name,
        selectedGroup: selectedGroup,
        selectedProfile: selectedProfile,
        defaultSetting: e.isDefault,
        enableAllSystemApp: e.enableAllSystemApp,
        rowId: e.id,
      });
    }
  };

  str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  render() {
    const groupList = this.state.selectGroupList;
    const profileList = this.state.selectProfileList;
    let groupOptions =
      groupList.length > 0 &&
      groupList.map((e, i) => {
        console.log("group", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    let profileOptions =
      profileList.length > 0 &&
      profileList.map((e, i) => {
        console.log("profile", e);
        return (
          <option key={i} value={e.id}>
            {e.name}
          </option>
        );
      });

    let arr = [];
    for (let key in EnrollmentTypeEnum) {
      arr.push(
        <option
          //selected={EnrollmentTypeEnum[key] === EnrollmentTypeEnum.QR}
          key={EnrollmentTypeEnum[key]}
          value={EnrollmentTypeEnum[key]}
        >
          {key}
        </option>
      );
    }
    let enrollMentTypeList = arr;

    //console.log("enrollList", enrollMentTypeList);

    return (
      <div>
        <Dialog
          visible={this.state.isLoading}
          modal={true}
          closable={false}
          showHeader={false}
          style={{ width: "20vw" }}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>
        <Dialog
          visible={this.state.isSuccess}
          modal={true}
          style={{ width: "25vw" }}
          closable
          showHeader
          onHide={this.onDialogueHide}
        >
          <SuccessDialog successMessage="Updated Successfully" />
        </Dialog>
        <Sidebar
          visible={this.state.visibleRight}
          position="right"
          baseZIndex={1000000}
          onHide={(e) =>
            this.setState({
              visibleRight: false,
              isUpdate: false,
              configName: "",
              selectedGroup: null,
              selectedProfile: null,
              defaultSetting: false,
              enableAllSystemApp: false,
            })
          }
        >
          <h2 style={{ fontWeight: "normal" }}>
            {this.state.isUpdate ? "Edit " : "Create "} Config
          </h2>
          <div className="content-section implementation">
            {this.state.isError && (
              <Alert
                variant="danger"
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                <b>{this.state.errorMsg}</b>
              </Alert>
            )}
            <form onSubmit={this.submitHandler}>
              <div className="p-grid">
                <div id="c-label" className="p-col-12 p-md-12">
                  {/* <div className="form-group">
                    <label htmlFor="enrollmentType">Enrollment Type</label>
                    <select
                      disabled
                      className="form-control"
                      id="enrollmentType"
                      name="enrollmentType"
                      value={EnrollmentTypeEnum.QR}
                    >
                      <option>Select</option>
                      {enrollMentTypeList}
                    </select>
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="configName">Config Name</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          ...this.state,
                          configName: e.target.value,
                        })
                      }
                      name="configName"
                      id="configName"
                      value={this.state.configName}
                      placeholder="Enter Config Name"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="selectedProfile">Select Profile</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        console.log("e in profile ", e.target);
                        let profileObject = profileList.find((obj) => {
                          console.log("object", obj.id);
                          return obj.id === e.target.value;
                        });
                        console.log("profile object", profileObject);
                        this.setState({
                          ...this.state,
                          selectedProfile: profileObject,
                        });
                      }}
                      id="selectedProfile"
                      name="selectedProfile"
                      value={
                        this.state.selectedProfile !== null &&
                        this.state.selectedProfile !== undefined
                          ? this.state.selectedProfile.id
                          : ""
                      }
                    >
                      <option>Select</option>
                      {profileOptions}
                    </select>
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="selectedGroup">Select Group</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        let groupobject = groupList.find((obj) => {
                          return obj.id === e.target.value;
                        });
                        this.setState({
                          ...this.state,
                          selectedGroup: groupobject,
                        });
                      }}
                      id="selectedGroup"
                      name="selectedGroup"
                      value={
                        this.state.selectedGroup !== undefined &&
                        this.state.selectedGroup !== null
                          ? this.state.selectedGroup.id
                          : ""
                      }
                    >
                      <option>Select</option>
                      {groupOptions}
                    </select>
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="" style={{ marginRight: "10px" }}>
                      Is Default
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          defaultSetting: this.str2bool(e.target.value),
                        });
                      }}
                      name="defaultSetting"
                      id="yes"
                      value={true}
                      checked={this.state.defaultSetting === true}
                    />
                    <label className="form-check-label" htmlFor="yes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          defaultSetting: this.str2bool(e.target.value),
                        });
                      }}
                      name="defaultSetting"
                      id="no"
                      value={false}
                      checked={this.state.defaultSetting === false}
                    />
                    <label className="form-check-label" htmlFor="no">
                      No
                    </label>
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group">
                    <label htmlFor="" style={{ marginRight: "10px" }}>
                      Enable all system apps
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          enableAllSystemApp: this.str2bool(e.target.value),
                        });
                      }}
                      name="enableAllSystemApp"
                      id="yes"
                      value={true}
                      checked={this.state.enableAllSystemApp === true}
                    />
                    <label className="form-check-label" htmlFor="yes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          enableAllSystemApp: this.str2bool(e.target.value),
                        });
                      }}
                      name="enableAllSystemApp"
                      id="no"
                      value={false}
                      checked={this.state.enableAllSystemApp === false}
                    />
                    <label className="form-check-label" htmlFor="no">
                      No
                    </label>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary ml-2">
                  Save Config
                </button>
              </div>
            </form>
          </div>
        </Sidebar>
        <button
          className="btn btn-primary"
          onClick={this.launchSide}
          style={{ float: "right" }}
        >
          + New Config
        </button>
      </div>
    );
  }
}
