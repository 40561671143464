import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CarService } from "../service/AllData";
import { InputText } from "primereact/inputtext";
import { SideMenu } from "../components/SideMenu";
import { Dialog } from "primereact/dialog";
import Loader from "../components/Loader";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

import "primeicons/primeicons.css";
// import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import api from "../service/ApiService";
import { getEnrollMentUrl } from "../service/ApiUrls";
import { OSTypeEnum, EnrollmentTypeEnum } from "../Enums";
import { OverlayPanel } from "primereact/overlaypanel";
import { confirmAlert } from "react-confirm-alert";
const helpIcon = require("../assets/icons/help_icon.png");

export class EnrollmentSettings extends Component {
  carService = new CarService();
  constructor(props) {
    super(props);
    this.data = "";
    this.state = {
      data: [],
      cars: [],
      totalRecords: 10,
      dfirst: 0,
      drows: 10,
      sortField: "",
      sortOrder: null,
      filters: null,
      currentPage: 1,
    };
    this.editorForRowEditing = this.editorForRowEditing.bind(this);
    this.onRowEditorValidator = this.onRowEditorValidator.bind(this);
    this.onRowEditInit = this.onRowEditInit.bind(this);
    this.onRowEditSave = this.onRowEditSave.bind(this);
    this.onRowEditCancel = this.onRowEditCancel.bind(this);
    this.child = React.createRef();
  }

  callupDateEnrollMent = async ({
    id,
    name,
    groupId,
    isDefault = false,
    osType = OSTypeEnum.ANDROID,
    type = EnrollmentTypeEnum.QR,
    profileId,
    enableAllSystemApp,
  } = {}) => {
    return api
      .put(getEnrollMentUrl + "/" + id, {
        id,
        name,
        groupId,
        isDefault,
        osType,
        type,
        profileId,
        enableAllSystemApp,
      })
      .then((res) => {
        console.log(res);
        const { isError, errorMsg, statusCode } = res.data.result;
        if (isError === false) {
          //this.callgetEnrollMentParameter();
          return { isError, errorMsg, statusCode };
        } else {
          console.log("object", res.data.result);
          return { isError, errorMsg, statusCode };
        }
      });
  };

  callgetEnrollMentParameter = ({
    type = EnrollmentTypeEnum.QR,
    first = 0,
    limit = 10,
  } = {}) => {
    this.setState({ isLoading: true }, () => {
      api
        .get(getEnrollMentUrl, {
          params: {
            type,
            first,
            limit,
          },
        })
        .then((res) => {
          console.log(res, "status", res.status);
          const {
            data: { result, data },
            status,
            config,
          } = res;

          console.log(
            "result",
            result,
            "data",
            data,
            "config",
            config,
            "status",
            status
          );
          if (status !== 200) {
            ///Some Error Message needs to be shown
          } else {
            const { isError } = result;
            console.log(typeof isError, "isError", isError);
            if (!isError) {
              const {
                content,
                totalRecords,
                first,
                rows,
                sortField,
                sortOrder,
              } = data;
              this.setState({
                data: content,
                cars: content,
                totalRecords: totalRecords,
                first: first,
                rows: rows,
                sortField: sortField,
                sortOrder: sortOrder,
                isLoading: false,
              });
            }
          }
        })
        .catch(
          (err, getLoginUrl) => {
            const {
              status,
              //config: { url },
            } = err.response;

            if (status !== 200) {
              //console.log("In redirect");
              //this.props.history.push("/login");
            }
          }
          //console.log("status", status, "url", config.url);
        );
    });
  };
  componentDidMount() {
    //api.get()
    this.callgetEnrollMentParameter();
  }
  onDialogueHide = () => {
    this.setState({ isLoading: false });
  };
  onDialogueHideError = () => {
    this.setState({ isError: false });
  };
  onDialogueHideSuccess = () => {
    this.setState({ isSuccess: false });
  };
  onSort = (event) => {
    console.log("onSort", event);
    this.setState({
      ...this.state,
      sortField: event.sortField,
      sortOrder: event.sortOrder,
    });
  };

  onPage = (event) => {
    console.log("event data ", event);
    this.setState(
      {
        dfirst: event.first,
        drows: event.rows,
        page: event.page,
        currentPage: event.page + 1,
      },
      () => {
        this.callgetEnrollMentParameter({
          first: this.state.page,
          limit: this.state.drows,
        });
      }
    );
    // this.setState({ ...this.state });
    // setTimeout(() => {
    //   const startIndex = event.first;
    //   const endIndex = event.first + this.state.rows;
    //   console.log(startIndex, endIndex);
    //   this.setState(
    //     {
    //       first: startIndex,
    //       cars: this.data.slice(startIndex, endIndex),
    //       loading: false,
    //     },
    //     () => console.log(this.state)
    //   );
    // }, 250);
  };

  onFilter = (event) => {
    console.log("onFilter value ", event, "this", this);
    this.setState({ filters: event.filters }, () =>
      console.log(this.state.cars)
    );
  };

  onBody(rowData, column) {
    const property = column.field;
    const getKeyByValue = (obj, value) =>
      Object.keys(obj).find((key) => obj[key] === value);
    return rowData.hasOwnProperty(property) &&
      typeof rowData[property] === "boolean"
      ? rowData[property].toString()
      : property === "type"
      ? getKeyByValue(EnrollmentTypeEnum, rowData[property])
      : property === "osType"
      ? getKeyByValue(OSTypeEnum, rowData[property])
      : rowData[property];
  }

  convertSentenceWord(text) {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    //console.log(finalResult);
    return finalResult;
  }

  genColumn = () => {
    const elements = this.carService.getFixColumns();
    const items = [];
    for (const [index, value] of elements.entries()) {
      const row = {
        field: value,
        header: this.convertSentenceWord(value),
        key: index,
      };
      items.push(row);
    }
    //console.log("items", items);

    return items;
  };

  onEditorValueChangeForRowEditing(props, value) {
    let updatedCars = [...props.value];
    updatedCars[props.rowIndex][props.field] = value;
    this.setState({ cars2: updatedCars });
  }

  editorForRowEditing(props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChangeForRowEditing(props, e.target.value)
        }
      />
    );
  }

  onRowEditorValidator(rowData) {
    return rowData.id !== null;
  }

  onRowEditInit(event) {
    console.log("from init", event);
  }

  onRowEditSave = async (event) => {
    console.log("from save", event);
    const response = await this.callupDateEnrollMent(event.data);
    console.log("response in row edit save", response);
  };

  onRowEditCancel(event) {
    console.log("from row edit cancel ", event);
  }

  rowDelete(rowData, column) {
    // alert("Do you want to delete the row");
    //console.log(rowData);
    api.delete(getEnrollMentUrl + "/" + rowData.id).then((response) => {
      console.log("apiresult", response);
      if (response.data.result.isError === false) {
        var array = [...this.state.cars]; // make a separate copy of the array
        var index = array.indexOf(rowData);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState(
            {
              cars: array,
              data: array,
              isLoading: false,
            },
            () => {
              confirmAlert({
                title: "Success Message",
                message: (
                  <p className="mod-sp">
                    Deleted Enrollment Setting Successfully
                  </p>
                ),
                buttons: [
                  {
                    label: "Ok",
                    onClick: () => {},
                  },
                ],
              });
            }
          );
        }
      } else if (response.data.result.isError) {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            confirmAlert({
              title: "Error Message",
              message: <p className="mod-p">{response.data.result.errorMsg}</p>,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {},
                },
              ],
            });
          }
        );
      }
    });
  }

  rowEdit(rowData, column) {
    this.setState({ rowData: rowData });
    let toUpdate = true;
    this.child.current.launchSide(rowData, toUpdate);
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <a href="#">
          <Tooltip
            target=".pi-pencil"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-pencil"
            data-pr-tooltip="Edit"
            onClick={() => {
              // console.log("onClick", rowData);
              this.rowEdit(rowData, column);
            }}
            style={{ fontSize: "1em", padding: "5px 8px", color: "#000000" }}
          ></i>
        </a>
        <a href="#">
          <Tooltip
            target=".pi-trash"
            position="top"
            mouseTrack
            mouseTracktop={20}
          />
          <i
            className="pi pi-trash"
            data-pr-tooltip="Delete"
            onClick={() => {
              // console.log("onClick", rowData);
              this.setState({ visible: true, rowData: rowData });
              // this.rowDelete(rowData, column);
            }}
            style={{ fontSize: "1em", padding: "5px 8px", color: "#000000" }}
          ></i>
        </a>
      </div>
    );
  }

  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Enroll", url: "enroll" },
      { label: "Enrollment Settings" },
    ];
    const home = { icon: "pi pi-home", url: "dashboard" };
    const footer = (
      <div>
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-success"
          onClick={() => {
            this.setState({ visible: false, isLoading: true });
            this.rowDelete(this.state.rowData);
          }}
        />
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            this.setState({ visible: false });
          }}
        />
      </div>
    );
    const deleteDialogue = (
      <Dialog
        header="Delete Enrollment Setting"
        visible={this.state.visible}
        style={{ width: "30vw" }}
        modal={true}
        footer={footer}
        onHide={() => this.setState({ visible: false })}
      >
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Do you want to delete this Enrollment Setting?
        </p>
      </Dialog>
    );
    let topHeaderDataTable = (
      <div className="p-col-12 p-lg-12 p-zz">
        <div className="p-col-9 inline-b p-ll">
          <BreadCrumb model={items} home={home} />
        </div>
        <div className="p-col-3 inline-b">
          <img
            src={helpIcon}
            width="20"
            height="20"
            onClick={(e) => this.op.toggle(e)}
            style={{ float: "right", cursor: "pointer" }}
          />
        </div>
      </div>
    );

    const dynamicColumns = this.genColumn().map((col, i) => {
      //console.log("col", col, "i", i);
      if (this.state.sortField === col.field) {
        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{
              height: "3.5em",
              overflowWrap: "break-word",
              textAlign: "center",
            }}
            sortable
          />
        );
      } else {
        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={this.onBody}
            // filter={true}
            //editor={(props) => this.editorForRowEditing(props, col.field)}
            style={{
              height: "3.5em",
              overflowWrap: "break-word",
              textAlign: "center",
            }}
            filterMatchMode="contains"
          />
        );
      }
    });
    const header = (
      <div className="row justify-content-end">
        <div className="p-col-12 p-md-4 p-lg-4">
          <h2 style={{ textAlign: "left", paddingTop: "5px" }}>
            Config - Enrollment
          </h2>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4">
          <div id="search" className="p-inputgroup">
            <InputText
              placeholder="Enter Search Item"
              onInput={(e) => this.setState({ globalFilter: e.target.value })}
            />
            <Button
              icon="pi pi-search"
              className="p-button-primary s-icon s-btn"
            />
          </div>
        </div>
        <div className="p-col-12 p-md-4 p-lg-4" style={{ textAlign: "right" }}>
          <SideMenu
            ref={this.child}
            apiParentCall={this.callgetEnrollMentParameter}
            apiUpdateCall={this.callupDateEnrollMent}
          />
        </div>
      </div>
    );
    // const header = (
    //   <div className="row justify-content-end">
    //     <div className="p-col-12 p-md-4 p-lg-4">
    //       <h2 style={{ textAlign: "left" }}>Config - Enrollment</h2>
    //     </div>
    //     <div className="p-col-12 p-md-4 p-lg-4" style={{ padding: "1em" }}>
    //       <div id="search" className="p-inputgroup">
    //         <input
    //           type="text"
    //           placeholder="Enter Search Item Here"
    //           onChange={(e) => {}}
    //         />
    //         <Button
    //           icon="pi pi-search"
    //           className="p-button-primary s-icon s-btn"
    //         />
    //       </div>
    //     </div>
    //     <div className="p-col-12 p-md-4 p-lg-4">
    //       <SideMenu
    //         ref={this.child}
    //         apiParentCall={this.callgetEnrollMentParameter}
    //         apiUpdateCall={this.callupDateEnrollMent}
    //       />
    //     </div>
    //   </div>
    // );

    let dataTable = (
      <DataTable
        ref={(el) => (this.dt = el)}
        value={this.state.cars}
        style={{ marginBottom: "20px" }}
        sortField={this.state.sortField}
        sortOrder={this.state.sortOrder}
        onSort={this.onSort}
        //onPage={this.onPage}
        filters={this.state.filters}
        onFilter={this.onFilter}
        // editMode="row"
        globalFilter={this.state.globalFilter}
        rowEditorValidator={this.onRowEditorValidator}
        onRowEditInit={this.onRowEditInit}
        onRowEditSave={this.onRowEditSave}
        onRowEditCancel={this.onRowEditCancel}
        header={header}
        emptyMessage="No records found"
        paginator
        totalRecords={this.state.totalRecords}
        onPage={this.onPage}
        first={this.state.dfirst}
        currentPage={this.state.currentPage}
        lazy
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={this.state.drows}
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
      >
        {dynamicColumns}
        {/* <Column
          header="Edit"
          rowEditor={true}
          style={{ width: "70px", textAlign: "center" }}
        ></Column> */}
        <Column
          header="Action"
          body={this.actionTemplate.bind(this)}
          style={{ textAlign: "center", width: "8em" }}
        />
      </DataTable>
    );

    let headerDiv = <div className="p-col p-ll">{topHeaderDataTable}</div>;

    return (
      <div className="p-col-12 p-grid">
        {headerDiv}
        {deleteDialogue}
        <Dialog
          visible={this.state.isLoading}
          modal
          style={{ width: "20vw" }}
          closable={false}
          showHeader={false}
          onHide={this.onDialogueHide}
        >
          <Loader />
        </Dialog>

        {/* <Button
          type="button"
          label="Toggle"
          onClick={(e) => this.op.toggle(e)}
        /> */}
        <OverlayPanel
          ref={(el) => (this.op = el)}
          id="overlay_panel"
          showCloseIcon={true}
        >
          <div
            className="col"
            style={{ width: "fit-content", maxWidth: "300px", padding: "0px" }}
          >
            <div className="helptip-title">
              <b>How to : Enrollment Settings</b>
            </div>
            <div className="helptip-des">
              Before starting to manage devices, a user needs to define their
              enrollment settings and then enroll their devices into COMMLINK
              Mobionizer portal using those settings. Users can choose either a
              profile or group during creation so that devices can be enrolled
              with that profile's or group's policies applied on them.
            </div>
          </div>
        </OverlayPanel>
        <div className="p-grid bg-col p-lg-12 datatable-paginator-demo">
          {dataTable}
        </div>
      </div>
    );
  }
}
