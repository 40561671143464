import React, { Component } from "react";
import { AppMap } from "../components/AppMap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "../assets/style.css";
import { Checkbox } from "primereact/checkbox";
import { BreadCrumb } from "primereact/breadcrumb";
import api from "../service/ApiService";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import {
  getDeviceSearchData,
  getDevicesUrl,
  getAppManagemnetUrl,
  baseUrl,
} from "../service/ApiUrls";
import { AppTypeEnum } from "../Enums";
// import DataUsageDialog from "../components/DataUsageDialog";
import { Dialog } from "primereact/dialog";
const device = require("../assets/icons/device.png");

export class ViewDeviceInfo extends Component {
  constructor(props) {
    super(props);

    this.onCityChange = this.onCityChange.bind(this);
    if (props.history.location.state !== undefined) {
      this.state = {
        overlays: null,
        openDetails: false,
        openTime: false,
        allApps: [],
        applist: [],
        cities: [],
        datauses: [],
        ...props.history.location.state.e,
        first1: 0,
        rows1: 10,
        first2: 0,
        rows2: 10,
        currentPage: 1,
      };
    } else {
      this.state = { overlays: null, allApps: [], applist: [], cities: [] };
    }
  }
  onDialogueHide = () => {
    this.setState({ openDetails: false });
  };
  onDialogueHide2 = () => {
    this.setState({ openTime: false });
  };
  callgetAppListByType = async ({
    apptype = AppTypeEnum.SYSTEMAPP,
    first = 0,
    limit = 9,
  } = {}) => {
    return api
      .get(getAppManagemnetUrl, {
        params: {
          // apptype,
          first,
          limit,
        },
      })
      .then((res) => {
        const {
          data,
          result: { isError, errMsg, statusCode },
        } = res.data;
        //console.log(data, "isError", isError, "error msg", errMsg);
        //this.setState({ systemApps: data.content, isLoading: false });
        if (isError === false)
          return { data, result: { isError, errMsg, statusCode } };
        // else return { result: { isError, errMsg, statusCode } };
      })
      .catch((err) => {
        console.log("err", err);
        //localStorage.setItem("loggedIn", false);
        //window.location = process.env.PUBLIC_URL + "/login";
      });
  };

  callAllApps = async () => {
    this.setState({ visible: true });
    let response = await this.callgetAppListByType({
      // apptype: AppTypeEnum.SYSTEMAPP,
    });
    this.setState({ applist: response.data.content, visible: false });
  };

  componentDidMount() {
    api
      .get(
        baseUrl +
          "/devices/" +
          this.props.history.location.state.e.id +
          "/app-wise-screentime"
      )
      .then((res) => {
        if (res.data.result.isError === false) {
          let date2 = res.data.data.updateTime;
          let serviceName2 = date2?.substring(3, 10);
          this.setState({
            content2: res.data.data.monthlyScreenTime,
            updateTime: res.data.data.updateTime,
            month2: serviceName2,
          });
        } else {
        }
      });
    api
      .get(
        baseUrl +
          "/devices/" +
          this.props.history.location.state.e.id +
          "/app-wise-datausage"
      )
      .then((res) => {
        if (res.data.result.isError === false) {
          let date = res.data.data.updateTime;
          let serviceName = date?.substring(3, 10);
          this.setState({
            datauses: res.data.data.monthlyDataUsage,
            updateTime2: res.data.data.updateTime,
            month: serviceName,
          });
        } else {
        }
      });
    api
      .get(getDevicesUrl + "/" + this.props.history.location.state.e.id)
      .then((res) => {
        let options, overlays;
        if (res.data.data.currentLocation !== null) {
          options = {
            center: {
              lat: Number(res.data.data.currentLocation.latitude),
              lng: Number(res.data.data.currentLocation.longitude),
            },
            zoom: 19,
          };

          overlays = [
            {
              position: {
                lat: Number(res.data.data.currentLocation.latitude),
                lng: Number(res.data.data.currentLocation.longitude),
              },
              title: res.data.data.currentLocation.address,
            },
          ];
        } else {
          options = null;
          overlays = null;
        }

        this.setState(
          {
            ...res.data.data,
            options: options,
            overlays: overlays,
          },
          () => {
            this.setState({ applist: this.state.availableApps });
          }
        );
      })
      .catch((err) => {
        // localStorage.setItem("loggedIn", false);
        // window.location = process.env.PUBLIC_URL + "/login";
      });
  }
  usages = (rowData) => {
    return `${rowData.dataUsageInMB} MB`;
  };

  screentimes = (rowData) => {
    const minutes = rowData.screenTimeInMinute % 60;
    const hours = Math.floor(rowData.screenTimeInMinute / 60);
    return `${Math.floor(hours) + " " + "hrs"} ${
      Math.round(minutes) + " " + "mins"
    }`;
    // return `${rowData.screenTimeInMinute} Minutes`;
  };
  onCityChange(e) {
    let selectedCities = [...this.state.cities];

    if (e.checked) selectedCities.push(e.value);
    else selectedCities.splice(selectedCities.indexOf(e.value), 1);

    this.setState({ cities: selectedCities });
  }
  render() {
    const items = [
      { label: "Dashboard", url: "dashboard" },
      { label: "Device Info", url: "device-info" },
      { label: "View Device Info" },
    ];
    const options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 8,
    };
    const home = { icon: "pi pi-home", url: "dashboard" };
    const appList = this.state.allApps;
    const appdata = this.state.applist;
    const selectApp =
      this.state.enabledApps !== undefined &&
      this.state.enabledApps !== null &&
      this.state.enabledApps.map((e, i) => {
        return (
          <>
            <div
              key={i}
              className="p-col-12 p-md-6 p-lg-6 p-xl-6 p-sm-6"
              style={{ display: "inline-block", padding: "5px" }}
            >
              <label key={e.id} className="app-icon" htmlFor={e.id}>
                <img
                  src={
                    e.appLogoBase64 !== null
                      ? `data:image/png;base64,${e.appLogoBase64}`
                      : `${process.env.PUBLIC_URL}/data/icons/androidapk` +
                        ".png"
                  }
                  width="35px"
                  style={{}}
                  className="app-img"
                />
                <Tooltip
                  target=".icon-title"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span className="icon-title" data-pr-tooltip={e.appName}>
                  {e.appName}
                </span>
                <Tooltip
                  target=".lineup"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span className="icon-des lineup" data-pr-tooltip={e.appId}>
                  {e.appId}
                </span>
                <input type="checkbox" name={e.appName} value={e} id={e.id} />
              </label>
            </div>
          </>
        );
      });
    const allApp =
      this.state.applist !== undefined &&
      this.state.applist !== null &&
      this.state.applist.map((e, i) => {
        return (
          <>
            <div
              key={i}
              className="p-col-6"
              style={{ display: "inline-block", padding: "0px" }}
            >
              <label key={e.id} className="app-icon" htmlFor={e.id}>
                <img
                  src={
                    e.appLogoBase64 !== null
                      ? `data:image/png;base64,${e.appLogoBase64}`
                      : `${process.env.PUBLIC_URL}/data/icons/androidapk` +
                        ".png"
                  }
                  width="35px"
                  style={{}}
                  className="app-img"
                />
                <Tooltip
                  target=".icon-title"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span className="icon-title" data-pr-tooltip={e.appName}>
                  {e.appName}
                </span>
                <Tooltip
                  target=".lineup"
                  position="top"
                  mouseTrack
                  mouseTracktop={20}
                />
                <span
                  className="icon-des lineup"
                  data-pr-tooltip={e.versionName}
                >
                  {e.versionName}
                </span>
                <input type="checkbox" name={e.appName} value={e} id={e.id} />
              </label>
            </div>
          </>
        );
      });

    return (
      <div className="p-col-12 p-grid">
        <div className="p-col-12 p-lg-12 ">
          <div className="content-section implementation">
            <BreadCrumb model={items} home={home} />
          </div>
        </div>
        <div className="p-grid bg-col p-lg-12">
          <div className="p-col">
            <div className="col-lg-12" style={{ marginBottom: "10px" }}>
              <h2>View Device Info</h2>
            </div>
            <div className="col-lg-12" style={{ padding: "0px 25px" }}>
              <div
                className="row align-items-start"
                style={{ border: "1px solid gray", borderRadius: "10px" }}
              >
                <div
                  className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-sm-6"
                  style={{ display: "inline-block" }}
                >
                  <div className="dblock-list">
                    <div className="dblock-imgblcok">
                      <img
                        src={
                          `${process.env.PUBLIC_URL}/data/icons/device` + ".png"
                        }
                        alt="Device"
                        width="80px"
                        className="device-img"
                      />
                    </div>
                    <div className="dblock-infoblcok" style={{ width: "70%" }}>
                      <Tooltip
                        target=".info-title"
                        position="top"
                        mouseTrack
                        mouseTracktop={20}
                      />
                      <Tooltip
                        target=".info-serial"
                        position="top"
                        mouseTrack
                        mouseTracktop={20}
                      />
                      <span
                        data-pr-tooltip={this.state.deviceName}
                        className="info-title"
                      >
                        <b>Device Name: </b>
                        {this.state.deviceName}
                      </span>
                      <span className="info-title">
                        <b>IMEI1: </b>
                        {this.state.imei1}
                      </span>
                      <span className="info-title">
                        <b>IMEI2: </b>
                        {this.state.imei2}
                      </span>
                      <span className="info-title">
                        <b>Model: </b>
                        {this.state.model}
                      </span>
                      <Tooltip
                        target=".info-title"
                        position="top"
                        mouseTrack
                        mouseTracktop={20}
                      />
                      <span
                        data-pr-tooltip={this.state.profileName}
                        className="info-title"
                      >
                        <b>Profile Name: </b>
                        {this.state.profileName}
                      </span>

                      <span
                        data-pr-tooltip={this.state.serialNumber}
                        className="info-title"
                      >
                        <b>Serial Number: </b>
                        {this.state.serialNumber}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ display: "inline-block" }}>
                  <div className="dblock-list">
                    <div className="dblock-infoblcok">
                      <span className="info-title">
                        <b>Battery Status: </b>
                        {this.state.availableBattery}
                      </span>
                      <span className="info-title">
                        <b>Available Storage(GB): </b>
                        {this.state.availableStorageInGB}
                      </span>
                      <span className="info-title">
                        <b>IP Address: </b>
                        {this.state.ipAddress}
                      </span>
                      <span className="info-title">
                        <b>OS Type: </b>
                        {this.state.osTypeSt}
                      </span>
                      <span className="info-title">
                        <b>OS Version: </b>
                        {this.state.osVersion}
                      </span>
                      <span className="info-title">
                        <b>Group: </b>
                        {this.state.groupName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ display: "inline-block" }}>
                  <div className="dblock-list">
                    <div className="dblock-infoblcok">
                      <span id="info-title" className="info-title">
                        <b>Monthly Data Usage(MB): </b>
                        <a onClick={() => this.setState({ openDetails: true })}>
                          <span className="lineup2">
                            {this.state.monthlyDataUsageInMB}
                          </span>
                        </a>
                      </span>
                      <Tooltip
                        target="#info-title"
                        position="top"
                        mouseTrack
                        mouseTracktop={20}
                      />
                      <span
                        className="info-title"
                        data-pr-tooltip="Monthly Mobile Data Usage(MB)"
                      >
                        <b>Monthly Mobile Data Usage(MB): </b>
                        <span className="lineup2">
                          {this.state?.monthlyDataUsageWithMDataInMB}
                        </span>
                      </span>
                      <Tooltip
                        target=".info-title"
                        position="top"
                        mouseTrack
                        mouseTracktop={20}
                      />
                      <span
                        data-pr-tooltip={this.state.lastSeen}
                        className="info-title"
                      >
                        <b>Last Seen: </b>
                        {this.state.lastSeen}
                      </span>
                      <span id="info-title" className="info-title">
                        <a onClick={() => this.setState({ openTime: true })}>
                          <span className="lineup2">
                            <b>Monthly Screen Time</b>
                          </span>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: "10px" }}>
              <div className="p-col-12 cus-nav">
                <ul
                  className="nav nav-pills center justify-content-md-center mb-3 mt-3"
                  id="pills-tab"
                  role="tablist"
                  style={{
                    borderBottom: "1px solid gray",
                    paddingBottom: "10px",
                  }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="enabled-app"
                      data-toggle="pill"
                      href="#pills-enabled"
                      role="tab"
                      aria-controls="pills-enabled"
                      aria-selected="true"
                    >
                      Location
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="all-apps"
                      data-toggle="pill"
                      href="#pills-all-apps"
                      role="tab"
                      aria-controls="pills-all-apps"
                      aria-selected="false"
                    >
                      Apps
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-enabled"
                    role="tabpanel"
                    aria-labelledby="enabled-app"
                  >
                    <div className="container">
                      <div className="row align-items-start">
                        <div className="col">
                          <div
                            className="box"
                            style={{
                              border: "1px solid gray",
                              borderRadius: "5px",
                              padding: "6px",
                            }}
                          >
                            <div
                              className="col-lg-12"
                              style={{ marginBottom: "10px" }}
                            >
                              <h2>Current location</h2>
                            </div>
                            {this.state?.currentLocation !== null &&
                              this.state?.currentLocation !== undefined && (
                                <>
                                  <AppMap
                                    options={
                                      this.state?.options !== undefined &&
                                      this.state?.options
                                    }
                                    overlays={
                                      this.state?.overlays !== undefined &&
                                      this.state?.overlays
                                    }
                                  ></AppMap>
                                </>
                              )}
                          </div>
                        </div>
                        <div className="col">
                          <h4 className="her">Information</h4>
                          <div>
                            <p>
                              <b>Current Location: </b>
                              <span>
                                {this.state?.currentLocation !== null &&
                                  this.state?.currentLocation?.address !==
                                    undefined &&
                                  this.state?.currentLocation?.address}
                              </span>
                            </p>
                            <p>
                              <b>Time: </b>
                              <span>
                                {this.state?.currentLocation !== null &&
                                  this.state?.currentLocation?.updateTime !==
                                    undefined &&
                                  this.state?.currentLocation?.updateTime}
                              </span>
                            </p>
                            <p>
                              {/* <b>Last Seen: </b> */}
                              {/* <span>12 Apr 2020 10:00 AM</span> */}
                            </p>
                            <p>
                              <b>Provider: </b>
                              <span>
                                {this.state?.currentLocation !== null &&
                                  this.state?.currentLocation
                                    ?.locationProvider !== undefined &&
                                  this.state?.currentLocation?.locationProvider}
                              </span>
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Link
                              to={{
                                pathname: "/location-history",
                                state: {
                                  detail: this.state,
                                  imei: this.state?.imei1,
                                },
                              }}
                              className="btn btn-primary"
                            >
                              Device Timeline
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-all-apps"
                    role="tabpanel"
                    aria-labelledby="all-apps"
                  >
                    <div className="container">
                      <div className="row align-items-start">
                        <div className="p-col-12 p-md-6 p-xl-6">
                          <div
                            className="p-col-12"
                            style={{
                              background: "#199e48",
                              color: "#ffffff",
                              display: "flex",
                              padding: "9px",
                            }}
                          >
                            <h2 style={{ marginRight: "10px" }}>
                              Enabled Apps
                            </h2>
                          </div>
                          <div
                            id="cuscheck"
                            className="col-12 p-z"
                            style={{
                              background: "#f1f3f1",
                              maxHeight: " 350px",
                              overflowX: "hidden",
                              overflowY: "auto",
                            }}
                          >
                            {selectApp}
                          </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-xl-6">
                          <div
                            className="p-col-12"
                            style={{
                              background: "#199e48",
                              color: "#ffffff",
                            }}
                          >
                            <div className="row justify-content-between">
                              <div className="col-4">
                                <h2 style={{ paddingL: "10px" }}>All Apps</h2>
                              </div>
                              <div className="col-8">
                                <div className="row justify-content-end">
                                  <div className="col-6">
                                    {/* <button className="btn4">
                                      Add to Device
                                    </button> */}
                                  </div>
                                  <div className="col-6">
                                    {/* <button className="btn4">
                                      Add to Profile
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="cuscheck"
                            className="col-12 p-z"
                            style={{
                              background: "#f1f3f1",
                              maxHeight: " 350px",
                              overflowX: "hidden",
                              overflowY: "scroll",
                            }}
                          >
                            {allApp}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header={"Monthly Data Usage" + " ( IMEI " + this.state.imei1 + " )"}
          visible={this.state.openDetails}
          modal={true}
          style={{ width: "60vw" }}
          closable
          showHeader
          onHide={this.onDialogueHide}
        >
          <p style={{ paddingLeft: "5px" }}>
            Date Range:{" "}
            <b>
              From 01-{this.state?.month} to {this.state?.updateTime2}
            </b>
          </p>
          <DataTable
            value={this.state.datauses}
            paginator
            // paginatorTemplate={template2}
            first={this.state.first2}
            // rows={this.state.rows2}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            // onPage={this.onCustomPage2}
            paginatorClassName="justify-content-end"
            className="mt-6"
            responsiveLayout="scroll"
            style={{ wordBreak: "break-all" }}
          >
            <Column
              field="appDetail.appName"
              header="App Name"
              style={{ width: "35%" }}
            ></Column>
            <Column
              field="appDetail.appId"
              header="App Id"
              style={{ width: "45%" }}
            ></Column>
            <Column
              field="dataUsageInMB"
              header="Data Usage "
              body={this.usages}
              style={{ width: "20%" }}
            ></Column>
          </DataTable>
        </Dialog>
        <Dialog
          header={"Monthly Screen Time " + "( IMEI " + this.state.imei1 + " )"}
          visible={this.state.openTime}
          modal={true}
          style={{ width: "60vw" }}
          closable
          showHeader
          onHide={this.onDialogueHide2}
        >
          <p style={{ paddingLeft: "5px" }}>
            Date Range:{" "}
            <b>
              From 01-{this.state?.month2} to {this.state?.updateTime}
            </b>
          </p>
          <DataTable
            value={this.state.content2}
            paginator
            // paginatorTemplate={template2}
            first={this.state.first1}
            // rows={this.state.rows2}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            // onPage={this.onCustomPage2}
            paginatorClassName="justify-content-end"
            className="mt-6"
            responsiveLayout="scroll"
            style={{ wordBreak: "break-all" }}
          >
            <Column
              field="appDetail.appName"
              header="App Name"
              style={{ width: "30%" }}
            ></Column>
            <Column
              field="appDetail.appId"
              header="App Id"
              style={{ width: "45%" }}
            ></Column>
            <Column
              field="screenTimeInMinute"
              header="Usage Time"
              body={this.screentimes}
              style={{ width: "25%" }}
            ></Column>
          </DataTable>
        </Dialog>
      </div>
    );
  }
}
